import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import TextArea from "../../Components/TextArea/TextArea";
import classes from "./HomeSignUp.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { AppContext } from "../../Context/AppContext";
import { openExternalLink } from "../Footer/Footer";
import { inputChangeHandler } from "../../HelperFunctions/inputChangeHandler";
import { signUpTypes } from "../../Utilities/types";

const HomeSignUp = () => {
  // Context
  const { contactRef, signUp, requestState } = useContext(AppContext);

  // States
  const [signUpDetails, setSignUpDetails] = useState<signUpTypes>({
    fullname: "",
    phone_number: "",
    email_address: "",
    message: "",
  });

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  // Effects
  useEffect(() => {
    if (requestState?.data) {
      setSignUpDetails({
        fullname: "",
        phone_number: "",
        email_address: "",
        message: "",
      });
    }
  }, [requestState?.data]);

  return (
    <section className={classes.container} ref={contactRef}>
      <div className={classes.textSection} data-aos="zoom-in-right">
        <h2>
          INTERESTED <br /> IN EXPLORING FURTHER
        </h2>
        <p>{"Initiate a conversation today".toUpperCase()}</p>

        <div>
          <div>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                openExternalLink("https://wa.me/+2348058573915");
              }}
            >
              <path d="M13.6625 2.82605C12.9258 2.08555 12.0483 1.4985 11.0813 1.09915C10.1143 0.699796 9.07705 0.496139 8.03013 0.500055C3.64339 0.500055 0.0683074 4.05804 0.0642893 8.42603C0.0642893 9.82502 0.431944 11.186 1.12607 12.391L0 16.5L4.223 15.398C5.39074 16.0322 6.6999 16.3641 8.03013 16.363H8.03415C12.4219 16.363 15.996 12.805 16 8.43303C16.0009 7.39109 15.7947 6.35927 15.3934 5.39698C14.992 4.43469 14.4044 3.56094 13.6625 2.82605ZM8.03013 15.021C6.84397 15.0201 5.67978 14.7024 4.65896 14.101L4.41788 13.957L1.91261 14.611L2.58162 12.178L2.42491 11.927C1.76173 10.8773 1.411 9.66205 1.41336 8.42203C1.41336 4.79604 4.38373 1.83805 8.03415 1.83805C8.90381 1.8365 9.76517 2.00632 10.5686 2.33774C11.372 2.66916 12.1016 3.15561 12.7152 3.76904C13.3309 4.38019 13.819 5.10658 14.1514 5.90636C14.4838 6.70614 14.6539 7.5635 14.6519 8.42903C14.6479 12.068 11.6775 15.021 8.03013 15.021ZM11.6615 10.087C11.4636 9.98802 10.4862 9.50902 10.3024 9.44102C10.1195 9.37602 9.98593 9.34202 9.85535 9.54002C9.72175 9.73702 9.34003 10.186 9.22551 10.315C9.111 10.448 8.99246 10.463 8.79357 10.365C8.59568 10.265 7.95379 10.057 7.19437 9.38002C6.60171 8.85503 6.20492 8.20503 6.08639 8.00803C5.97187 7.81003 6.07534 7.70403 6.17479 7.60503C6.26218 7.51703 6.37267 7.37303 6.47212 7.25903C6.57257 7.14503 6.60572 7.06103 6.67102 6.92903C6.73631 6.79503 6.70517 6.68103 6.65595 6.58203C6.60572 6.48303 6.20894 5.50604 6.04118 5.11204C5.88046 4.72304 5.71672 4.77704 5.59417 4.77204C5.47966 4.76504 5.34606 4.76504 5.21245 4.76504C5.11158 4.7676 5.01231 4.79085 4.92088 4.83335C4.82944 4.87585 4.7478 4.93669 4.68106 5.01204C4.49824 5.21004 3.98694 5.68904 3.98694 6.66603C3.98694 7.64303 4.70015 8.58203 4.8006 8.71503C4.89904 8.84803 6.2009 10.847 8.19889 11.707C8.67102 11.912 9.04269 12.033 9.333 12.125C9.81014 12.277 10.2411 12.254 10.5846 12.205C10.9663 12.147 11.7609 11.725 11.9287 11.262C12.0934 10.798 12.0934 10.402 12.0432 10.319C11.994 10.235 11.8604 10.186 11.6615 10.087Z" />
            </svg>
          </div>

          <a href="tel:+2348058573915">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.4141 0.38325C1.54533 0.252217 1.70291 0.150569 1.8764 0.0850425C2.04989 0.0195163 2.23532 -0.00839198 2.42041 0.00316718C2.6055 0.0147263 2.78601 0.0654889 2.95 0.152091C3.11399 0.238693 3.2577 0.359157 3.3716 0.5055L4.71785 2.235C4.9646 2.55225 5.0516 2.9655 4.9541 3.3555L4.54385 4.998C4.52279 5.08308 4.52401 5.17215 4.54739 5.25662C4.57078 5.34109 4.61554 5.41811 4.67735 5.48025L6.5201 7.323C6.58231 7.38494 6.65946 7.42977 6.74408 7.45316C6.82869 7.47655 6.91791 7.4777 7.0031 7.4565L8.64485 7.04625C8.83733 6.99841 9.03816 6.99481 9.23223 7.03573C9.4263 7.07665 9.60857 7.16102 9.76535 7.2825L11.4949 8.628C12.1166 9.11175 12.1736 10.0305 11.6171 10.5862L10.8416 11.3617C10.2866 11.9167 9.4571 12.1605 8.68385 11.8882C6.70436 11.1927 4.90726 10.0596 3.42635 8.57325C1.94013 7.09256 0.807017 5.29573 0.111353 3.3165C-0.160147 2.544 0.0836032 1.71375 0.638603 1.15875L1.4141 0.38325ZM8.25035 0.375C8.25035 0.275544 8.28986 0.180161 8.36019 0.109835C8.43051 0.0395088 8.5259 0 8.62535 0L11.6254 0C11.7248 0 11.8202 0.0395088 11.8905 0.109835C11.9608 0.180161 12.0004 0.275544 12.0004 0.375V3.375C12.0004 3.47446 11.9608 3.56984 11.8905 3.64016C11.8202 3.71049 11.7248 3.75 11.6254 3.75C11.5259 3.75 11.4305 3.71049 11.3602 3.64016C11.2899 3.56984 11.2504 3.47446 11.2504 3.375V1.28025L8.14085 4.3905C8.07044 4.46091 7.97494 4.50047 7.87535 4.50047C7.77577 4.50047 7.68027 4.46091 7.60985 4.3905C7.53944 4.32008 7.49988 4.22458 7.49988 4.125C7.49988 4.02542 7.53944 3.92991 7.60985 3.8595L10.7201 0.75H8.62535C8.5259 0.75 8.43051 0.710491 8.36019 0.640165C8.28986 0.569839 8.25035 0.474456 8.25035 0.375Z"
              />
            </svg>
          </a>
          <p>
            Machima Plaza, Mambolo Junction,
            <br /> Wuse Zone 2, FCT Abuja.
          </p>
        </div>
      </div>

      <form data-aos="fade-up">
        <Input
          placeholder="FULL NAME"
          name="fullname"
          value={signUpDetails.fullname}
          onChange={(e) => inputChangeHandler(e, setSignUpDetails)}
        />
        <Input
          placeholder="PHONE"
          type="phone"
          name="phone_number"
          value={signUpDetails.phone_number}
          onChange={(e) => inputChangeHandler(e, setSignUpDetails)}
        />
        <Input
          placeholder="EMAIL"
          type="email"
          name="email_address"
          value={signUpDetails.email_address}
          onChange={(e) => inputChangeHandler(e, setSignUpDetails)}
        />
        <TextArea
          placeholder="MESSAGE"
          name="message"
          value={signUpDetails.message}
          onChange={(e) => inputChangeHandler(e, setSignUpDetails)}
        />

        <Button
          subType="normal"
          disabled={
            !signUpDetails.email_address ||
            !signUpDetails.fullname ||
            !signUpDetails.message ||
            !signUpDetails.phone_number
          }
          onClick={() => {
            signUp(signUpDetails);
          }}
          loading={requestState.isLoading}
        >
          <span>{"Start conversation".toUpperCase()}</span>
          <svg
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.36307 0.119629L8.08108 1.3905L12.9858 6.1107H0.5V7.89481H12.9798L8.08108 12.6092L9.36307 13.8801L16.5 7L9.36307 0.119629Z" />
          </svg>
        </Button>

        <div className={classes.socials}>
          <div>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                openExternalLink("https://wa.me/+2348058573915");
              }}
            >
              <path d="M13.6625 2.82605C12.9258 2.08555 12.0483 1.4985 11.0813 1.09915C10.1143 0.699796 9.07705 0.496139 8.03013 0.500055C3.64339 0.500055 0.0683074 4.05804 0.0642893 8.42603C0.0642893 9.82502 0.431944 11.186 1.12607 12.391L0 16.5L4.223 15.398C5.39074 16.0322 6.6999 16.3641 8.03013 16.363H8.03415C12.4219 16.363 15.996 12.805 16 8.43303C16.0009 7.39109 15.7947 6.35927 15.3934 5.39698C14.992 4.43469 14.4044 3.56094 13.6625 2.82605ZM8.03013 15.021C6.84397 15.0201 5.67978 14.7024 4.65896 14.101L4.41788 13.957L1.91261 14.611L2.58162 12.178L2.42491 11.927C1.76173 10.8773 1.411 9.66205 1.41336 8.42203C1.41336 4.79604 4.38373 1.83805 8.03415 1.83805C8.90381 1.8365 9.76517 2.00632 10.5686 2.33774C11.372 2.66916 12.1016 3.15561 12.7152 3.76904C13.3309 4.38019 13.819 5.10658 14.1514 5.90636C14.4838 6.70614 14.6539 7.5635 14.6519 8.42903C14.6479 12.068 11.6775 15.021 8.03013 15.021ZM11.6615 10.087C11.4636 9.98802 10.4862 9.50902 10.3024 9.44102C10.1195 9.37602 9.98593 9.34202 9.85535 9.54002C9.72175 9.73702 9.34003 10.186 9.22551 10.315C9.111 10.448 8.99246 10.463 8.79357 10.365C8.59568 10.265 7.95379 10.057 7.19437 9.38002C6.60171 8.85503 6.20492 8.20503 6.08639 8.00803C5.97187 7.81003 6.07534 7.70403 6.17479 7.60503C6.26218 7.51703 6.37267 7.37303 6.47212 7.25903C6.57257 7.14503 6.60572 7.06103 6.67102 6.92903C6.73631 6.79503 6.70517 6.68103 6.65595 6.58203C6.60572 6.48303 6.20894 5.50604 6.04118 5.11204C5.88046 4.72304 5.71672 4.77704 5.59417 4.77204C5.47966 4.76504 5.34606 4.76504 5.21245 4.76504C5.11158 4.7676 5.01231 4.79085 4.92088 4.83335C4.82944 4.87585 4.7478 4.93669 4.68106 5.01204C4.49824 5.21004 3.98694 5.68904 3.98694 6.66603C3.98694 7.64303 4.70015 8.58203 4.8006 8.71503C4.89904 8.84803 6.2009 10.847 8.19889 11.707C8.67102 11.912 9.04269 12.033 9.333 12.125C9.81014 12.277 10.2411 12.254 10.5846 12.205C10.9663 12.147 11.7609 11.725 11.9287 11.262C12.0934 10.798 12.0934 10.402 12.0432 10.319C11.994 10.235 11.8604 10.186 11.6615 10.087Z" />
            </svg>
          </div>

          <a href="tel:+2348058573915">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.4141 0.38325C1.54533 0.252217 1.70291 0.150569 1.8764 0.0850425C2.04989 0.0195163 2.23532 -0.00839198 2.42041 0.00316718C2.6055 0.0147263 2.78601 0.0654889 2.95 0.152091C3.11399 0.238693 3.2577 0.359157 3.3716 0.5055L4.71785 2.235C4.9646 2.55225 5.0516 2.9655 4.9541 3.3555L4.54385 4.998C4.52279 5.08308 4.52401 5.17215 4.54739 5.25662C4.57078 5.34109 4.61554 5.41811 4.67735 5.48025L6.5201 7.323C6.58231 7.38494 6.65946 7.42977 6.74408 7.45316C6.82869 7.47655 6.91791 7.4777 7.0031 7.4565L8.64485 7.04625C8.83733 6.99841 9.03816 6.99481 9.23223 7.03573C9.4263 7.07665 9.60857 7.16102 9.76535 7.2825L11.4949 8.628C12.1166 9.11175 12.1736 10.0305 11.6171 10.5862L10.8416 11.3617C10.2866 11.9167 9.4571 12.1605 8.68385 11.8882C6.70436 11.1927 4.90726 10.0596 3.42635 8.57325C1.94013 7.09256 0.807017 5.29573 0.111353 3.3165C-0.160147 2.544 0.0836032 1.71375 0.638603 1.15875L1.4141 0.38325ZM8.25035 0.375C8.25035 0.275544 8.28986 0.180161 8.36019 0.109835C8.43051 0.0395088 8.5259 0 8.62535 0L11.6254 0C11.7248 0 11.8202 0.0395088 11.8905 0.109835C11.9608 0.180161 12.0004 0.275544 12.0004 0.375V3.375C12.0004 3.47446 11.9608 3.56984 11.8905 3.64016C11.8202 3.71049 11.7248 3.75 11.6254 3.75C11.5259 3.75 11.4305 3.71049 11.3602 3.64016C11.2899 3.56984 11.2504 3.47446 11.2504 3.375V1.28025L8.14085 4.3905C8.07044 4.46091 7.97494 4.50047 7.87535 4.50047C7.77577 4.50047 7.68027 4.46091 7.60985 4.3905C7.53944 4.32008 7.49988 4.22458 7.49988 4.125C7.49988 4.02542 7.53944 3.92991 7.60985 3.8595L10.7201 0.75H8.62535C8.5259 0.75 8.43051 0.710491 8.36019 0.640165C8.28986 0.569839 8.25035 0.474456 8.25035 0.375Z"
              />
            </svg>
          </a>
          <p>
            Machima Plaza, Mambolo Junction,
            <br /> Wuse Zone 2, FCT Abuja.
          </p>
        </div>
      </form>
    </section>
  );
};

export default HomeSignUp;
